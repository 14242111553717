/**
 * @flow
 *
 * @format
 */

import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardBody, CardTitle, Col, Container, Row } from 'reactstrap';
import UseCalculatedFields from 'src/hooks/UseCalculatedFields';
import { MissionLastResults, MissionLastScores } from 'src/components/Missions';
import { MissionLastReviews } from 'src/components/Missions';
import { MissionStatCard } from 'src/components/Missions';
import { useListScenarios } from 'src/services/data/scenarios';
import { useGetScenarioScoresAndReviews, useScoresAndReviews } from 'src/services/data/scoresAndReviews';
import { type Scenario } from 'src/services/data/scenarios';
import { type Review, Score } from 'src/services/data/scoresAndReviews';
import { useListMissionResults, useListScenariosSales } from 'src/services/data/results';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import moment from 'moment';
import { useFilters } from 'src/contexts/FiltersContext';
import { useTranslation } from 'react-i18next';
import { exportAndDownloadCSV } from 'src/helpers/csvHelper';

const MissionDetailsScreen = () => {
  const { t } = useTranslation();
  const { scenarios, scenarioIsLoading } = useListScenarios();
  const { scoresAndReviews, scoresAndReviewsIsLoading } = useScoresAndReviews();
  const { scenarioId } = useParams<string>();
  const { scenarioResults, scenarioResultsIsLoading } = useListMissionResults(scenarioId);
  const { scenarioScoresAndReviews, scenarioScoresAndReviewsIsLoading } = useGetScenarioScoresAndReviews(scenarioId);
  const [currentScenario, setCurrentScenario] = React.useState<Scenario | null>(null);
  const { scenariosSales } = useListScenariosSales(scenarioId);
  const [scenarioReviews, setScenarioReviews] = React.useState<Array<Review> | any>(null);
  const [scenarioScores, setScenarioScores] = React.useState<Array<Score> | any>(null);
  const [missionNotFinishedCount, setMissionNotFinishedCount] = React.useState<number>(0);
  const [missionFinishedCount, setMissionFinishedCount] = React.useState<number>(0);
  const { missionPlayed, averageScore, averageNote, averageDuration, scoresCount, notesCount } =
    UseCalculatedFields(scenarioId);
  const { filterStartDate, filterEndDate } = useFilters();

  const exportCSV = useCallback(() => {
    console.log('exporting csv');
    exportAndDownloadCSV(
      t,
      scenarioResults
        .filter((item: any) => {
          return (
            moment(parseInt(item.startDate)).format('yyyy-MM-DD') >= filterStartDate &&
            moment(parseInt(item.startDate)).format('yyyy-MM-DD') <= filterEndDate
          );
        })
        .map((details) => ({
          ...details,
          score: scenarioScores[`${details.startDate}-${details.userId}`]
            ? scenarioScores[`${details.startDate}-${details.userId}`].score
            : 0,
          userName: scenarioScores[`${details.startDate}-${details.userId}`]
            ? scenarioScores[`${details.startDate}-${details.userId}`].userName
            : undefined,
        })),
      `eva_stats_${currentScenario.id}_${filterStartDate}_${filterEndDate}.csv`,
      [{ value: currentScenario.id, label: currentScenario.name.fr }],
    );
  }, [scenarioScores, scenarioResults, currentScenario, filterStartDate, filterEndDate]);

  ChartJS.register(ArcElement, Tooltip, Legend);

  React.useEffect(() => {
    if (!scenarioIsLoading && scenarios) {
      setCurrentScenario(scenarios[scenarioId]);
    }
  }, [scenarios, scenarioIsLoading, scenarioId]);

  React.useEffect(() => {
    if (!scoresAndReviewsIsLoading && scoresAndReviews) {
      setScenarioReviews(scoresAndReviews[scenarioId]?.reviews);
      setScenarioScores(scoresAndReviews[scenarioId]?.scores);
    }
  }, [scoresAndReviews, scoresAndReviewsIsLoading, scenarioId]);

  React.useEffect(() => {
    if (scenarioScoresAndReviews && !scenarioScoresAndReviewsIsLoading) {
      if (scenarioScoresAndReviews.reviews) {
        console.log(scenarioScoresAndReviews.reviews);
        setScenarioReviews(scenarioScoresAndReviews.reviews);
      }
      if (scenarioScoresAndReviews.scores) {
        console.log(scenarioScoresAndReviews.scores);
        setScenarioScores(scenarioScoresAndReviews.scores);
      }
    }
  }, [scenarioReviews, scenarioScores, scenarioScoresAndReviews, scenarioScoresAndReviewsIsLoading]);

  React.useEffect(() => {
    if (scenarioResults) {
      const results = Object.values(scenarioResults).filter((item: any) => {
        return (
          moment(parseInt(item.startDate)).format('yyyy-MM-DD') >= filterStartDate &&
          moment(parseInt(item.startDate)).format('yyyy-MM-DD') <= filterEndDate
        );
      });
      let missionNotFinished = 0;
      let missionFinished = 0;
      Object.values(results).forEach((result: any) => {
        if (result.notFinished) {
          missionNotFinished++;
        } else {
          missionFinished++;
        }
      });
      setMissionNotFinishedCount(missionNotFinished);
      setMissionFinishedCount(missionFinished);
    }
  }, [scenarioResultsIsLoading, scenarioResults, filterEndDate, filterStartDate]);

  return (
    <>
      {!scenarioIsLoading && currentScenario && (
        <div className="header text-white eva-gradient pb-8 pt-5 pt-md-8">
          <Container fluid>
            <h1 className="text-white">{currentScenario.name.fr}</h1>
            <h3 className="text-white">{currentScenario.missionText.fr}</h3>
            <p>{currentScenario.pitch.fr}</p>
          </Container>
          <Container fluid>
            <div className="header-body mt-5">
              <Row>
                <MissionStatCard
                  title={t('screens.missionDetails.statCards.missionPlayed')}
                  stat={missionPlayed}
                  icon="fas fa-user"
                  iconColor="bg-yellow"
                />
                <MissionStatCard
                  title={t('screens.missionDetails.statCards.averageScore')}
                  stat={averageScore}
                  icon="fas fa-trophy"
                  iconColor="bg-danger"
                />
                <MissionStatCard
                  title={t('screens.missionDetails.statCards.averageNote')}
                  stat={averageNote}
                  icon="fas fa-star"
                  iconColor="bg-warning"
                />
                <MissionStatCard
                  title={t('screens.missionDetails.statCards.averageDuration')}
                  stat={averageDuration + ' min'}
                  icon="fas fa-clock"
                  iconColor="bg-info"
                />
                <MissionStatCard
                  title={t('screens.missionDetails.statCards.scoresCount')}
                  stat={scoresCount}
                  icon="fas fa-trophy"
                  iconColor="bg-primary"
                />
                <MissionStatCard
                  title={t('screens.missionDetails.statCards.notesCount')}
                  stat={notesCount}
                  icon="fas fa-star"
                  iconColor="bg-success"
                />

                <Col lg="6" xl="4">
                  <Card className="mb-4 mb-xl-4">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                            {t('screens.missionDetails.pieChart.title')} <br />
                            <span className="text-dark text-lowercase">
                              {missionFinishedCount && missionPlayed
                                ? Number.parseFloat((missionFinishedCount / missionPlayed) * 100).toFixed(2)
                                : 0}
                              % {t('screens.missionDetails.pieChart.ofFinishedMissions')}
                            </span>
                          </CardTitle>
                        </div>
                        <Pie
                          data={{
                            labels: [
                              t('screens.missionDetails.pieChart.unfinishedMissions'),
                              t('screens.missionDetails.pieChart.finishedMissions'),
                            ],
                            datasets: [
                              {
                                data: [missionNotFinishedCount, missionFinishedCount],
                                backgroundColor: ['rgba(255, 99, 132, 0.2)', 'rgba(54, 162, 235, 0.2)'],
                                borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)'],
                                borderWidth: 1,
                              },
                            ],
                          }}
                        />
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="4">
                  <Card className="card-stats mb-4 mb-xl-4">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                            {t('screens.missionDetails.sales.title')}
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">{scenariosSales.total + ' €'}</span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape text-white rounded-circle shadow bg-primary">
                            <i className="fas fa-euro-sign" />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <div className="col">
                          <p className="h5 text-muted mb-0">
                            {t('screens.missionDetails.sales.freeSales')}: {scenariosSales.totalFree}
                          </p>
                          <p className="h5 text-muted mb-0">
                            {t('screens.missionDetails.sales.welcomeSales')}: {scenariosSales.totalWelcome + ' €'}
                          </p>
                          <p className="h5 text-muted mb-0">
                            {t('screens.missionDetails.sales.launchSales')}: {scenariosSales.totalLaunch + ' €'}
                          </p>
                          <p className="h5 text-muted mb-0">
                            {t('screens.missionDetails.sales.fullPriceSales')}: {scenariosSales.totalBasic + ' €'}
                          </p>
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      )}

      {!scoresAndReviewsIsLoading && scenarioReviews && (
        <div className="mt--6">
          <MissionLastReviews reviews={scenarioReviews} />
        </div>
      )}
      {scenarioScoresAndReviews && scenarioScoresAndReviews.scores && (
        <div className="mt-5">
          <MissionLastScores results={scenarioScoresAndReviews.scores} />
        </div>
      )}
      {!scenarioResultsIsLoading && scenarioResults && (
        <div className="mt-5">
          <MissionLastResults results={scenarioResults} exportCSV={exportCSV} />
        </div>
      )}
    </>
  );
};

export default MissionDetailsScreen;
