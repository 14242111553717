/**
 * @format
 *
 * @flow
 */

import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

type Props = {
  review: any,
};

const LastReviews = ({ review }: Props) => {
  const timestamp = parseInt(review.timestamp);
  return (
    <tr>
      <th scope="row">
        <span className="mb-0 text-sm">{timestamp ? moment(timestamp).format('DD/MM/YYYY HH:mm') : '-'}</span>
      </th>
      <th scope="row">
        <Link to={`/missionDetails/${review.scenarioId}`}>{review.scenarioId}</Link>
      </th>
      <th scope="row">
        <Link to={`/userDetails/${review.userId}`}>
          {review.userName === 'atlantideAgent' ? 'Tisseur EVA' : review.userName}
        </Link>
      </th>
      <th scope="row">{review.note}</th>
      <th scope="row">{review.comment ?? '-'}</th>
    </tr>
  );
};

export default LastReviews;
