/**
 * @flow
 *
 * @format
 */

type Routes = {
  landing: string,
  login: string,
  dashboard: string,
  missionsList: string,
  missionDetails: string,
  userDetails: string,
};

export const routes: Routes = {
  landing: '',
  login: '/login',
  dashboard: '/dashboard',
  missionsList: '/missionsList',
  missionDetails: '/missionDetails/:scenarioId',
  userDetails: '/userDetails/:userId',
};
