/**
 * @flow
 *
 * @format
 */

import moment from 'moment';
import React from 'react';
import { useFilters } from 'src/contexts/FiltersContext';
import { useListResults } from 'src/services/data/results';
import { useScoresAndReviews } from 'src/services/data/scoresAndReviews';

const UseCalculatedFields = (scenarioId: string) => {
  const { results, resultsIsLoading } = useListResults();
  const { scoresAndReviews, scoresAndReviewsIsLoading } = useScoresAndReviews();
  const [scenarioScores, setScenarioScores] = React.useState();
  const [scenarioReviews, setScenarioReviews] = React.useState();
  const [scenarioResults, setScenarioResults] = React.useState();
  const { filterStartDate, filterEndDate } = useFilters();

  React.useEffect(() => {
    setScenarioScores(scoresAndReviews[scenarioId] ? scoresAndReviews[scenarioId].scores : null);
    setScenarioReviews(scoresAndReviews[scenarioId] ? scoresAndReviews[scenarioId].reviews : null);
    setScenarioResults(results[scenarioId] ?? null);
  }, [scenarioId, results, resultsIsLoading, scoresAndReviews, scoresAndReviewsIsLoading]);

  // Calculate average score
  let averageScore;
  let scoresCount;
  if (!scoresAndReviewsIsLoading && scenarioScores) {
    const scores = [];
    Object.values(scenarioScores).forEach((score) => {
      if (
        moment(parseInt(score.timestamp)).format('yyyy-MM-DD') >= filterStartDate &&
        moment(parseInt(score.timestamp)).format('yyyy-MM-DD') <= filterEndDate
      ) {
        scores.push(score.score);
      }
    });

    scoresCount = scores.length !== 0 ? scores.length : 0;
    let total = 0;
    for (let i = 0; i < scores.length; i++) {
      total += scores[i];
    }

    const avg = total / scores.length;
    const roundedAvg = Math.round(avg);
    if (!scores.length) {
      averageScore = 0;
    } else {
      averageScore = roundedAvg ?? null;
    }
  } else {
    averageScore = null;
  }

  // Calculate average note
  let averageNote;
  let notesCount;
  if (!scoresAndReviewsIsLoading && scenarioReviews) {
    const notes = [];
    Object.values(scenarioReviews).forEach((review) => {
      if (
        moment(parseInt(review.timestamp)).format('yyyy-MM-DD') >= filterStartDate &&
        moment(parseInt(review.timestamp)).format('yyyy-MM-DD') <= filterEndDate
      ) {
        notes.push(review.note);
      }
    });
    notesCount = notes.length !== 0 ? notes.length : 0;

    let total = 0;
    for (let i = 0; i < notes.length; i++) {
      total += notes[i];
    }

    const avg = total / notes.length;
    const roundedAvg = (Math.round(avg * 10) / 10).toFixed(1);
    if (!notes.length) {
      averageNote = 0;
    } else {
      averageNote = roundedAvg ?? null;
    }
  } else {
    averageNote = null;
  }

  // Calculate played missions
  let missionPlayed;
  if (scenarioResults) {
    let total = [];
    Object.values(scenarioResults).forEach((result) => {
      Object.values(result).forEach((r) => {
        if (
          moment(parseInt(r.startDate)).format('yyyy-MM-DD') >= filterStartDate &&
          moment(parseInt(r.startDate)).format('yyyy-MM-DD') <= filterEndDate
        ) {
          total.push(r);
        }
      });
    });
    missionPlayed = total.length;
  } else {
    missionPlayed = null;
  }

  let averageDuration;
  if (scenarioResults) {
    let durations = [];
    Object.values(scenarioResults).forEach((result) => {
      Object.values(result).forEach((r) => {
        if (
          moment(parseInt(r.startDate)).format('yyyy-MM-DD') >= filterStartDate &&
          moment(parseInt(r.startDate)).format('yyyy-MM-DD') <= filterEndDate
        ) {
          const duration = Math.round(r.duration / 60000);
          durations.push(duration);
        }
      });
    });

    let total = 0;
    for (let i = 0; i < durations.length; i++) {
      total += durations[i];
    }

    const avg = total / durations.length;
    const roundedAvg = (Math.round(avg * 10) / 10).toFixed(1);
    if (!durations.length) {
      averageDuration = 0;
    } else {
      averageDuration = roundedAvg ?? null;
    }
  } else {
    averageDuration = null;
  }

  return {
    missionPlayed,
    averageScore,
    averageNote,
    averageDuration,
    scoresCount,
    notesCount,
  };
};

export default UseCalculatedFields;
