/**
 * @flow
 *
 * @format
 */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavItem, NavLink, Nav, Container, Row, Col } from 'reactstrap';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <>
      <footer className="py-5">
        <Container>
          <Row className="align-items-center justify-content-xl-between">
            <Col xl="6">
              <div className="copyright text-center text-xl-left text-muted">
                © {new Date().getFullYear()}{' '}
                <a className="font-weight-bold ml-1" href="https://atlantide.io" target="_blank" rel="noreferrer">
                  {t('general.atlantide')}
                </a>
              </div>
            </Col>
            <Col xl="6">
              <Nav className="nav-footer justify-content-center justify-content-xl-end">
                <NavItem>
                  <NavLink href="https://editor.mission-eva.yvelines.fr" target="_blank">
                    {t('general.evaStudio')}
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default Footer;
