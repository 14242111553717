/**
 * @flow
 *
 * @format
 */

import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'reactstrap';

type BoughtScenario = {
  transactionDate: number,
  cityId: string,
  scenarioId: string,
  origin: string,
  transactionId: string,
};

interface Props {
  boughtScenarios: Array<BoughtScenario>;
}

const UserBoughtScenarios = ({ boughtScenarios }: Props) => {
  const { t } = useTranslation();
  return (
    <Table className="align-items-center table-flush" responsive>
      <thead className="thead-light">
        <tr>
          <th scope="col">{t('screens.userDetails.userBoughtScenarios.fields.date')}</th>
          <th scope="col">{t('screens.userDetails.userBoughtScenarios.fields.cityId')}</th>
          <th scope="col">{t('screens.userDetails.userBoughtScenarios.fields.scenarioId')}</th>
          <th scope="col">{t('screens.userDetails.userBoughtScenarios.fields.origin')}</th>
          <th scope="col">{t('screens.userDetails.userBoughtScenarios.fields.transactionId')}</th>
        </tr>
      </thead>
      <tbody>
        {Object.values(boughtScenarios)
          .sort((a, b) => b.transactionDate - a.transactionDate)
          .map((scenario, s) => {
            return (
              <tr key={s}>
                <th scope="row">
                  <span className="mb-0 text-sm">{moment(scenario.transactionDate).format('DD/MM/YYYY HH:mm')}</span>
                </th>
                <th scope="row">{scenario.cityId}</th>
                <th scope="row">{scenario.scenarioId}</th>
                <th scope="row">{scenario.origin}</th>
                <th scope="row">{scenario.transactionId}</th>
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
};

export default UserBoughtScenarios;
