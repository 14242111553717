/**
 * @format
 * @flow
 */

import React from 'react';
import { functions, firebase } from '../../services/Firebase/firebase-config';
import Pagination from '../Pagination/Pagination';
import LeaderBoard from './LeaderBoard';
import Rank from './Rank';
import { CardHeader, Row } from 'reactstrap';
import Lottie from 'lottie-react';
import { useTranslation } from 'react-i18next';

export type Score = {
  score: number,
  userName: string,
  collarNumber: string,
  userId: string,
  photoStorageLink: string | null,
  position: number,
};

const Ranking = () => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [sortedScores, setSortedScores] = React.useState<Array<Score>>([]);
  const [allScores, setAllScores] = React.useState<Array<Score>>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  const getAllScores = async () => {
    setLoading(true);
    const result: firebase.functions.HttpsCallableResult = await functions.httpsCallable('getRanking')({});
    if (result.data) {
      const { ranking } = result.data;
      setAllScores(ranking);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    getAllScores();
  }, []);

  React.useEffect(() => {
    if (allScores.length) {
      const firstPageIndex = (currentPage - 1) * 16;
      const lastPageIndex = firstPageIndex + 16;
      const allScoresList = allScores;
      const newSortedScores: Array<Score> = Object.values(allScoresList)
        .sort((a, b) => b.score - a.score)
        .slice(firstPageIndex, lastPageIndex);
      setSortedScores(newSortedScores);
    }
  }, [allScores, currentPage]);

  return (
    <div style={{ marginBottom: '10px' }}>
      <CardHeader className="border-0">
        <Row className="align-items-center">
          <div className="col">
            <h3 style={{ textTransform: 'uppercase' }}>{t('screens.dashboard.leaderboard')}</h3>
          </div>
        </Row>
      </CardHeader>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Lottie animationData={require('../../assets/lottie/loader.json')} />
        </div>
      ) : (
        <>
          <LeaderBoard leaders={allScores.sort((a, b) => b.score - a.score).slice(0, 3)} />
          <div
            style={{
              marginTop: '10px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                marginRight: 30,
                marginLeft: 30,
                width: '400px',
              }}
            >
              {sortedScores.slice(0, 8).map((player: Score, index: number) => {
                const realIndex = allScores.findIndex((score) => {
                  return score.userId === player.userId;
                });

                return <Rank player={player} realIndex={realIndex + 1} key={index} />;
              })}
            </div>
            <div style={{ width: '400px', marginLeft: 30, marginRight: 30 }}>
              {sortedScores.slice(8, 16).map((player: Score, index: number) => {
                const realIndex = allScores.findIndex((score) => {
                  return score.userId === player.userId;
                });
                return <Rank player={player} realIndex={realIndex + 1} key={index} />;
              })}
            </div>
          </div>
          <div
            style={{
              marginTop: '20px',
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Pagination
              siblingCount={1}
              currentPage={currentPage}
              totalCount={allScores.length}
              pageSize={16}
              onPageChange={(page: string | number) => setCurrentPage(Number(page))}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Ranking;
