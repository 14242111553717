/**
 * @flow
 *
 * @format
 */

import React, { useState } from 'react';
import {
  Card,
  CardFooter,
  CardHeader,
  Container,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Spinner,
  Table,
} from 'reactstrap';
import { Pagination } from 'src/components/Pagination';
import { MissionTableLines } from 'src/components/Missions';
import { useListScenarios } from 'src/services/data/scenarios';
import { type Scenario } from 'src/services/data/scenarios';
import { useTranslation } from 'react-i18next';

const MissionsListScreen = () => {
  const { t } = useTranslation();
  const { scenarios, scenarioIsLoading } = useListScenarios();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentTableData, setCurrentTableData] = useState<Scenario | any>();
  const [searchText, setSearchText] = useState<string>('');

  React.useEffect(() => {
    if (!scenarioIsLoading) {
      const firstPageIndex = (currentPage - 1) * 25;
      const lastPageIndex = firstPageIndex + 25;
      setCurrentTableData(
        Object.values(scenarios)
          .filter((item) => {
            if (searchText === '') {
              return item;
            } else {
              const scenarioName = item.name.fr;
              const scenarioId = item.id;
              return scenarioName.toLowerCase().includes(searchText) || scenarioId.toLowerCase().includes(searchText);
            }
          })
          .slice(firstPageIndex, lastPageIndex),
      );
    } else {
      setCurrentTableData({});
    }
  }, [scenarios, currentPage, scenarioIsLoading, searchText]);

  return (
    <>
      <>
        <div className="header eva-gradient pb-8 pt-5 pt-md-8"></div>
      </>
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <FormGroup className="mb-4">
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-search" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder={t('screens.missionsList.searchText')}
                  type="text"
                  onChange={(e) => setSearchText(e.target.value.toLowerCase())}
                />
              </InputGroup>
            </FormGroup>
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">{t('screens.missionsList.title')}</h3>
              </CardHeader>
              {scenarioIsLoading && (
                <div style={{ textAlign: 'center', marginBottom: '5vh' }}>
                  <Spinner size="lg">{t('general.loading')}</Spinner>
                </div>
              )}
              {!scenarioIsLoading && scenarios && (
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">{t('screens.missionsList.fields.name')}</th>
                      <th scope="col">{t('screens.missionsList.fields.id')}</th>
                      <th scope="col">{t('screens.missionsList.fields.cityId')}</th>
                      <th scope="col">{t('screens.missionsList.fields.releaseDate')}</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <MissionTableLines scenarios={currentTableData} />
                </Table>
              )}

              {Object.keys(scenarios).length > 25 && (
                <CardFooter className="py-4">
                  <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={Object.keys(scenarios).length}
                    pageSize={25}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </CardFooter>
              )}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default MissionsListScreen;
