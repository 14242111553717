/**
 * @format
 * @flow
 */
import React from 'react';
import Lottie from 'lottie-react';
import { storage } from '../../services/Firebase/firebase-config';

type Props = {
  photoStorageLink: string | null,
  size?: number,
  isFirst: boolean | null,
};

const LeaderPicture = ({ photoStorageLink, size = 50, isFirst }: Props) => {
  const [url, setUrl] = React.useState<string>();
  const [loadingImage, setLoadingImage] = React.useState<boolean>(false);

  const retrievePhoto = async () => {
    if (photoStorageLink) {
      setLoadingImage(true);
      const splitPath = photoStorageLink.split('/');
      const fileName = splitPath[splitPath.length - 1];
      const pathWithoutFileName = photoStorageLink.replace(fileName, '');
      const picture = storage.ref(pathWithoutFileName).child(fileName);
      const newUrl = await picture.getDownloadURL();
      setUrl(newUrl);
      setLoadingImage(false);
    }
  };

  React.useEffect(() => {
    retrievePhoto();
  }, []);

  return (
    <div
      style={{
        height: size + 5,
        width: size + 5,
        borderRadius: size + 5,
        borderColor: '#AB34F225',
        borderWidth: 5,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {!loadingImage ? (
        url ? (
          <div
            style={{
              height: size,
              width: size,
              borderRadius: size,
              border: `5px solid ${isFirst ? '#FEC500' : '#AB34F250'}`,
              backgroundImage: `url(${url})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
            }}
          ></div>
        ) : (
          <img
            src={require('../../assets/img/no_pic.png')}
            style={{
              height: size,
              width: size,
              borderRadius: size,
              border: `5px solid ${isFirst ? '#FEC500' : '#AB34F250'}`,
            }}
          />
        )
      ) : (
        <div
          style={{
            height: size,
            width: size,
            borderRadius: size,
          }}
        >
          <Lottie animationData={require('../../assets/lottie/loader.json')} />
        </div>
      )}
    </div>
  );
};

export default LeaderPicture;
