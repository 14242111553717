/**
 * @flow
 *
 * @format
 */

import { db } from '../Firebase/firebase-config';

const fetcher = async (fetchingRef: string) => {
  const result = await db.ref(fetchingRef).once('value');
  if (!result.exists()) {
    throw new Error(`Can't fetch ${fetchingRef} values`);
  }
  checkLastUpdateDate(true);
  return result.val();
};

const checkLastUpdateDate = (reloadData: ?boolean) => {
  const storedDate = JSON.stringify(localStorage.getItem('lastUpdate'));
  if (!storedDate || reloadData) {
    const date = new Date();
    localStorage.setItem('lastUpdate', JSON.stringify(date));
  }
};

export { fetcher, checkLastUpdateDate };
