/**
 * @flow
 *
 * @format
 */

import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
import { type Scenario } from 'src/services/data/scenarios';

export interface Props {
  scenarios: Array<Scenario>;
}

const MissionTableLines = ({ scenarios }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const goToMissionDetails = (scenarioId) => {
    history.push(`/missionDetails/${scenarioId}`);
  };

  return (
    <tbody>
      {scenarios &&
        Object.values(scenarios).map((scenario, s) => {
          return (
            <tr key={s}>
              <th scope="row">
                <span className="mb-0 text-sm">{scenario.name.fr}</span>
              </th>
              <td>{scenario.id}</td>
              <td>{scenario.cityId}</td>
              <td>{moment(scenario.releaseDate).format('DD/MM/YYYY HH:mm')}</td>
              <td>
                <Button
                  onClick={() => goToMissionDetails(scenario.id)}
                  size="sm"
                  className="mr-4 gradient-button"
                  style={{ fontSize: 12, padding: '5px 10px', textTransform: 'none', fontFamily: 'Nasalization' }}
                >
                  <span>{t('screens.missionsList.fields.stats')}</span>
                  <i className="ni ni-sound-wave" />
                </Button>
              </td>
            </tr>
          );
        })}
    </tbody>
  );
};

export default MissionTableLines;
