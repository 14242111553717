/**
 * @format
 *
 * @flow
 */

import React from 'react';
import { Link } from 'react-router-dom';

type Props = {
  game: any,
};

const TopGames = ({ game }: Props) => {
  return (
    <tr>
      <th scope="row">
        <Link to={`/missionDetails/${game.scenarioId}`}>{game.scenarioId}</Link>
      </th>
      <th scope="row">{game.averageNotes}</th>
      <th scope="row">{game.notesCount}</th>
    </tr>
  );
};

export default TopGames;
