/**
 * @flow
 *
 * @format
 */

import moment from 'moment';
import React from 'react';
import { useFilters } from 'src/contexts/FiltersContext';
import { useListResults } from 'src/services/data/results';
import { useScoresAndReviews } from 'src/services/data/scoresAndReviews';

const UseDashboardData = () => {
  const { scoresAndReviews, scoresAndReviewsIsLoading } = useScoresAndReviews();
  const { results, resultsIsLoading } = useListResults();
  const [scenarioScoresAndReviews, setScenarioScoresAndReviews] = React.useState();
  const [scenarioResults, setScenarioResults] = React.useState();
  const { filterStartDate, filterEndDate } = useFilters();

  React.useEffect(() => {
    setScenarioScoresAndReviews(JSON.parse(JSON.stringify(scoresAndReviews)) ?? null);
    setScenarioResults(JSON.parse(JSON.stringify(results)) ?? null);
  }, [scoresAndReviews, scoresAndReviewsIsLoading, resultsIsLoading, results]);

  let reviews = [];
  let resultsArray = [];
  let lastTenResults;
  let lastTenReviews;
  let fiveBestGames;
  let fiveWorstGames;
  let mostResolvedPuzzles = 'loading';
  let userWithMostResolved = 'loading';
  let numberOfPlayer;

  if (!scoresAndReviewsIsLoading && scenarioScoresAndReviews) {
    const scenarioIds = Object.keys(scenarioScoresAndReviews);
    Object.values(scenarioScoresAndReviews).forEach((scenarioData, s) => {
      const scenarioId = scenarioIds[s];
      const scenarioReviews = scenarioData.reviews;
      if (scenarioReviews) {
        Object.values(scenarioReviews).forEach((review) => {
          review.scenarioId = scenarioId;
          reviews.push(review);
        });
      }
    });
    lastTenReviews = Object.values(reviews)
      .filter((review) => {
        return (
          moment(parseInt(review.timestamp)).format('yyyy-MM-DD') >= filterStartDate &&
          moment(parseInt(review.timestamp)).format('yyyy-MM-DD') <= filterEndDate
        );
      })
      .sort((a, b) => b.timestamp - a.timestamp)
      .slice(0, 10);
  } else {
    lastTenReviews = null;
  }

  let allPuzzles = [];
  let resolvedPuzzles = [];
  let userResolvedPuzzles = [];
  let userIds = [];
  if (!resultsIsLoading && scenarioResults) {
    lastTenResults = [];

    const scenarioIds = Object.keys(scenarioResults);
    Object.values(scenarioResults).forEach((resultData, r) => {
      const scenarioId = scenarioIds[r];
      const userIds = Object.keys(resultData);
      Object.values(resultData).forEach((result, index) => {
        const userId = userIds[index];
        Object.values(result).forEach((res) => {
          if (
            moment(parseInt(res.startDate)).format('yyyy-MM-DD') >= filterStartDate &&
            moment(parseInt(res.startDate)).format('yyyy-MM-DD') <= filterEndDate
          ) {
            res.scenarioId = scenarioId;
            res.userId = userId;
            res.score = 0;
            if (
              scoresAndReviews &&
              scoresAndReviews[scenarioId] &&
              scoresAndReviews[scenarioId].scores &&
              scoresAndReviews[scenarioId].scores[`${res.startDate}-${userId}`]
            ) {
              res.score = scoresAndReviews[scenarioId].scores[`${res.startDate}-${userId}`].score || 0;
              res.userName = scoresAndReviews[scenarioId].scores[`${res.startDate}-${userId}`].userName;
            }
            resultsArray.push(res);
          }
        });
      });
    });
    allPuzzles = Object.values(resultsArray)
      .filter((e) => {
        if (
          moment(parseInt(e.startDate)).format('yyyy-MM-DD') >= filterStartDate &&
          moment(parseInt(e.startDate)).format('yyyy-MM-DD') <= filterEndDate
        ) {
          if (!userIds.includes(e.userId)) {
            userIds.push(e.userId);
          }
          return e;
        } else {
          return 0;
        }
      })
      .sort((a, b) => b.startDate - a.startDate);

    if (allPuzzles) {
      Object.values(scenarioIds).forEach((scenarioId) => {
        let puzzleCount = 0;
        Object.values(allPuzzles).forEach((puzzle) => {
          if (puzzle.scenarioId === scenarioId && puzzle.isSuccess && scenarioId !== '_metrics') {
            puzzleCount++;
          }
        });
        if (puzzleCount !== 0) {
          resolvedPuzzles.push({
            scenarioId,
            count: puzzleCount,
          });
        }
      });
      Object.values(userIds).forEach((userId) => {
        let puzzleCount = 0;
        Object.values(allPuzzles).forEach((puzzle) => {
          if (puzzle.userId === userId && puzzle.isSuccess) {
            puzzleCount++;
          }
        });
        if (puzzleCount !== 0) {
          userResolvedPuzzles.push({
            userId,
            count: puzzleCount,
          });
        }
      });
    }

    lastTenResults = Object.values(resultsArray)
      .sort((a, b) => b.startDate - a.startDate)
      .slice(0, 10);
  }

  let allGames = [];
  if (scenarioScoresAndReviews) {
    Object.values(scenarioScoresAndReviews).forEach((scenarioData, r) => {
      const scenarioIds = Object.keys(scenarioScoresAndReviews);
      const scenarioId = scenarioIds[r];
      if (scenarioData) {
        let newScenarioData = scenarioData;
        if (newScenarioData?.reviews) {
          let reviews = newScenarioData.reviews;
          const total = [];
          Object.values(reviews).forEach((review) => {
            if (
              moment(parseInt(review.timestamp)).format('yyyy-MM-DD') >= filterStartDate &&
              moment(parseInt(review.timestamp)).format('yyyy-MM-DD') <= filterEndDate
            ) {
              total.push(review.note);
            } else {
              return;
            }
          });

          let scenarioNotes = 0;
          for (let i = 0; i < total.length; i++) {
            scenarioNotes += total[i];
          }

          let averageNotes;
          if (total.length) {
            averageNotes = scenarioNotes / total.length;
            const roundedAvg = Math.round(averageNotes);
            newScenarioData.averageNotes = roundedAvg;
            newScenarioData.notesCount = total.length;
            newScenarioData.scenarioId = scenarioId;
          }
        }
        if (newScenarioData.averageNotes) {
          allGames.push(newScenarioData);
        }
      }
    });
  }

  fiveBestGames = Object.values(allGames)
    .sort((a, b) => b.averageNotes - a.averageNotes || b.notesCount - a.notesCount)
    .slice(0, 5);

  fiveWorstGames = Object.values(allGames)
    .sort((a, b) => a.averageNotes - b.averageNotes || b.notesCount - a.notesCount)
    .slice(0, 5);

  if (resolvedPuzzles) {
    mostResolvedPuzzles = resolvedPuzzles.sort((a, b) => b.count - a.count);
  }
  if (userResolvedPuzzles) {
    userWithMostResolved = userResolvedPuzzles.sort((a, b) => b.count - a.count);
  }

  numberOfPlayer = userIds.length ?? 0;

  return {
    resultsArray,
    lastTenResults,
    lastTenReviews,
    fiveBestGames,
    fiveWorstGames,
    mostResolvedPuzzles,
    userWithMostResolved,
    numberOfPlayer,
  };
};

export default UseDashboardData;
