/**
 * @flow
 *
 * @format
 */

import React from 'react';
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap';

type Props = {
  title: string,
  stat: number | string,
  icon: string,
  iconColor: string,
};

const MissionStatCard = ({ title, stat, icon, iconColor }: Props) => {
  return (
    <Col lg="6" xl="4">
      <Card className="card-stats mb-4 mb-xl-4">
        <CardBody>
          <Row>
            <div className="col">
              <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                {title}
              </CardTitle>
              <span className="h2 font-weight-bold mb-0">{stat ?? '-'}</span>
            </div>
            <Col className="col-auto">
              <div className={'icon icon-shape text-white rounded-circle shadow ' + iconColor}>
                <i className={icon} />
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

export default MissionStatCard;
