/**
 * @flow
 *
 * @format
 */

import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardFooter, CardHeader, Container, Row, Table, Button } from 'reactstrap';
import { Pagination } from 'src/components/Pagination';
import { useFilters } from 'src/contexts/FiltersContext';
import { type Result } from 'src/services/data/results';
import MissionResultsTableLines from './MissionResultsTableLines';

interface Props {
  results: Array<Result>;
  exportCSV: () => void;
}

const MissionLastResults = ({ results, exportCSV }: Props) => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentTableData, setCurrentTableData] = useState<any>();
  const { filterStartDate, filterEndDate } = useFilters();

  React.useEffect(() => {
    if (results) {
      const firstPageIndex = (currentPage - 1) * 10;
      const lastPageIndex = firstPageIndex + 10;
      setCurrentTableData(
        Object.values(results)
          .filter((item: any) => {
            return (
              moment(parseInt(item.startDate)).format('yyyy-MM-DD') >= filterStartDate &&
              moment(parseInt(item.startDate)).format('yyyy-MM-DD') <= filterEndDate
            );
          })
          .sort((a, b) => b.startDate - a.startDate)
          .slice(firstPageIndex, lastPageIndex),
      );
    } else {
      setCurrentTableData({});
    }
  }, [results, currentPage, filterStartDate, filterEndDate]);

  return (
    <>
      <Container fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="row">
                  <h3 className="mb-0">{t('screens.missionDetails.lastResults.title')}</h3>
                  <Button
                    size="sm"
                    className="ml-2 gradient-button"
                    style={{ fontSize: 12, padding: '5px 10px' }}
                    onClick={exportCSV}
                  >
                    {t('header.filters.exportCsv')}
                  </Button>
                </div>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">{t('screens.missionDetails.lastResults.fields.date')}</th>
                    <th scope="col">{t('screens.missionDetails.lastResults.fields.user')}</th>
                    <th scope="col">{t('screens.missionDetails.lastResults.fields.finished')}</th>
                    <th scope="col">{t('screens.missionDetails.lastResults.fields.success')}</th>
                    <th scope="col">{t('screens.missionDetails.lastResults.fields.progress')}</th>
                    <th scope="col">{t('screens.missionDetails.lastResults.fields.details')}</th>
                  </tr>
                </thead>
                <tbody>
                  {currentTableData &&
                    Object.values(currentTableData).map((result, s) => {
                      return <MissionResultsTableLines result={result} key={s} />;
                    })}
                </tbody>
              </Table>
              {!currentTableData || !Object.keys(currentTableData).length ? (
                <div className="mt-3 mx-auto">
                  <p>{t('screens.missionDetails.lastResults.noResults')}</p>
                </div>
              ) : null}
              {Object.values(results).filter((item: any) => {
                return (
                  moment(parseInt(item.startDate)).format('yyyy-MM-DD') >= filterStartDate &&
                  moment(parseInt(item.startDate)).format('yyyy-MM-DD') <= filterEndDate
                );
              }).length > 10 && (
                <CardFooter className="py-4">
                  <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={
                      Object.values(results).filter((item: any) => {
                        return (
                          moment(parseInt(item.startDate)).format('yyyy-MM-DD') >= filterStartDate &&
                          moment(parseInt(item.startDate)).format('yyyy-MM-DD') <= filterEndDate
                        );
                      }).length
                    }
                    pageSize={10}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </CardFooter>
              )}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default MissionLastResults;
