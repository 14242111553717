/**
 * @format
 *
 * @flow
 */

import React from 'react';
import { Link } from 'react-router-dom';
import { db } from 'src/services/Firebase/firebase-config';

type Props = {
  item: any,
};

const UsersMostResolved = ({ item }: Props) => {
  const [user, setUser] = React.useState<any>();

  const getUser = async (userId) => {
    const result = await db.ref(`users/${userId}`).once('value');
    if (result.exists()) {
      const resultValue = result.val();
      if (resultValue) {
        setUser(resultValue);
      }
    } else {
      setUser('-');
    }
  };

  React.useEffect(() => {
    if (item && item.userId) {
      getUser(item.userId);
    } else if (item && !item.userId) {
      setUser('-');
    }
  }, [item]);

  return (
    <tr>
      <th scope="row">
        <Link to={`/userDetails/${item.userId}`}>
          {user && user?.userName === 'atlantideAgent' ? 'Tisseur EVA' : user?.userName}
        </Link>
      </th>
      <th scope="row">{item.count}</th>
    </tr>
  );
};

export default UsersMostResolved;
