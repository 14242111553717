/**
 * @flow
 *
 * @format
 */

import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Row } from 'reactstrap';
import { db } from 'src/services/Firebase/firebase-config';
import { UserBoughtScenarios } from 'src/components/Users/';
import { useTranslation } from 'react-i18next';

const UserDetailsScreen = () => {
  const { t } = useTranslation();
  const [user, setUser] = useState<any>();
  const [loading, setLoading] = useState(true);
  const { userId } = useParams();

  const getUser = async () => {
    setLoading(true);
    const result = await db.ref(`users/${userId}`).once('value');
    if (result.exists()) {
      const resultValue = result.val();
      if (resultValue) {
        setUser(resultValue);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!user) {
      getUser().then();
    }
  }, [getUser, user]);

  return (
    <>
      <div className="header text-white eva-gradient pb-8 pt-5 pt-md-8"></div>
      <Container className="mt--8" fluid>
        <Col xl="12">
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="8">
                  <h3 className="mb-0">{t('screens.userDetails.title')}</h3>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              {!loading ? <Form>
                <h6 className="heading-small text-muted mb-4">{t('screens.userDetails.baseInfo')}</h6>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="input-username">
                          {t('screens.userDetails.fields.userName')}
                        </label>
                        <Input
                          className="form-control-alternative"
                          defaultValue={user.userName}
                          id="input-username"
                          type="text"
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="input-email">
                          {t('screens.userDetails.fields.email')}
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-email"
                          defaultValue={user.email ?? 'Utilisateur anonyme'}
                          type="email"
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="input-collarNumber">
                          {t('screens.userDetails.fields.collarNumber')}
                        </label>
                        <Input
                          className="form-control-alternative"
                          defaultValue={user.collarNumber}
                          id="input-collarNumber"
                          type="text"
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="input-registrationDate">
                          {t('screens.userDetails.fields.registrationDate')}
                        </label>
                        <Input
                          className="form-control-alternative"
                          defaultValue={
                            user?.registrationDate ? moment(user?.registrationDate).format('DD/MM/YYYY HH:mm') : ''
                          }
                          id="input-registrationDate"
                          type="text"
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="input-lastConnectionDate">
                          {t('screens.userDetails.fields.lastConnectionDate')}
                        </label>
                        <Input
                          className="form-control-alternative"
                          defaultValue={
                            user?.lastConnectionDate ? moment(user?.lastConnectionDate).format('DD/MM/YYYY HH:mm') : ''
                          }
                          id="input-lastConnectionDate"
                          type="text"
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <hr className="my-4"/>
                {user?.boughtScenarios && (
                  <>
                    <h6 className="heading-small text-muted mb-4">{t('screens.userDetails.fields.iap')}</h6>
                    <div className="pl-lg-4">
                      <Row>
                        <UserBoughtScenarios boughtScenarios={user?.boughtScenarios}/>
                      </Row>
                    </div>
                  </>
                )}
              </Form> : (
                <p>Loading...</p>
              )}
            </CardBody>
          </Card>
        </Col>
      </Container>
    </>
  );
};

export default UserDetailsScreen;
