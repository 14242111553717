/**
 * @flow
 *
 * @format
 */

import useSWR from 'swr';
import { fetcher } from './fetcher';

type Localized<T> = {|
  en: T,
  fr?: T,
  es?: T,
  ca?: T,
  ro?: T,
  de?: T,
  ru?: T,
  bg?: T,
|};

type LocalizedString = Localized<string>;

export type Scenario = {
  id: string,
  cityId: string,
  name: LocalizedString,
  releaseDate: number,
};

const useListScenarios = () => {
  const { data, error } = useSWR('releases/scenarios/catalog/prod', fetcher, {
    revalidateIfStale: true,
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
  });
  return {
    scenarios: data ?? [],
    scenarioIsLoading: !error && !data,
    scenariosIsError: error,
  };
};

const useGetScenario = (scenarioId) => {
  const { data, error } = useSWR(`releases/scenarios/catalog/prod/${scenarioId}`, fetcher, {
    revalidateIfStale: true,
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
  });
  return {
    scenario: data ?? {},
    scenarioIsLoading: !error && !data,
    scenariosIsError: error,
  };
};

export { useListScenarios, useGetScenario };
