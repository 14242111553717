/**
 * @flow
 *
 * @format
 */

import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { routes } from 'src/constants/routes';

// Screens
import { LoginScreen } from 'src/pages/Login/';
import { ProtectedRoute } from 'src/components/ProtectedRoute';
import { AuthProvider } from 'src/contexts/AuthContext';
import { AdminLayout } from 'src/layouts';
import { FiltersProvider } from './contexts/FiltersContext';
import { initI18n } from './assets/locales/i18n';

const App = () => {
  React.useEffect(() => {
    initI18n();
  }, []);
  return (
    <div className="" style={{ width: '100%' }}>
      <AuthProvider>
        <FiltersProvider>
          <Switch>
            <Route exact path={routes.login} component={LoginScreen} />
            <ProtectedRoute path={routes.landing} component={AdminLayout} />
          </Switch>
        </FiltersProvider>
      </AuthProvider>
    </div>
  );
};

export default App;
