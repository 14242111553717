/**
 * @format
 *
 * @flow
 */

import i18next from 'i18next';
import React from 'react';
import { Link } from 'react-router-dom';
import { useGetScenario } from 'src/services/data/scenarios';

type Props = {
  item: any,
};

const MostResolved = ({ item }: Props) => {
  const { scenario, scenarioIsLoading } = useGetScenario(item.scenarioId);
  const language = i18next.language.split('-')[0];

  return (
    <tr>
      <th scope="row">
        <Link to={`/missionDetails/${item.scenarioId}`}>{!scenarioIsLoading && scenario.name[language]}</Link>
      </th>
      <th scope="row">{item.count}</th>
    </tr>
  );
};

export default MostResolved;
