/**
 * @flow
 *
 * @format
 */

import React, { useContext, useState, useEffect } from 'react';
import moment from 'moment';

type FiltersContextType = {
  filterStartDate: any,
  filterEndDate: any,
  setDates: any,
  setDefaultDates: any,
};

type FiltersProviderProps = {
  children: any,
};

const initialContext = {
  filterStartDate: '',
  filterEndDate: '',
};

const FiltersContext = React.createContext<FiltersContextType>(initialContext);

export function useFilters() {
  return useContext(FiltersContext);
}

export function FiltersProvider({ children }: FiltersProviderProps) {
  const [loading, setLoading] = useState<boolean>(true);
  const [filterStartDate, setFilterStartDate] = useState<Date>(new Date());
  const [filterEndDate, setFilterEndDate] = useState<Date>(new Date());

  useEffect(() => {
    const startDate = JSON.parse(localStorage.getItem('filterStartDate'));
    const endDate = JSON.parse(localStorage.getItem('filterEndDate'));
    if (startDate && endDate) {
      setFilterStartDate(startDate);
      setFilterEndDate(endDate);
    } else {
      const { newStartDate, newEndDate } = setDefaultDates();
      setFilterStartDate(newStartDate);
      setFilterEndDate(newEndDate);
    }
    setLoading(false);
  }, []);

  const setDefaultDates = () => {
    const now = new Date();
    const then = new Date();
    const startDay = then.getDate() - 7;
    then.setDate(startDay);

    localStorage.setItem('filterStartDate', JSON.stringify(moment(then).format('yyyy-MM-DD')));
    localStorage.setItem('filterEndDate', JSON.stringify(moment(now).format('yyyy-MM-DD')));

    setFilterStartDate(moment(then).format('yyyy-MM-DD'));
    setFilterEndDate(moment(now).format('yyyy-MM-DD'));
    window.location.reload(false);

    return {
      newStartDate: moment(then).format('yyyy-MM-DD'),
      newEndDate: moment(now).format('yyyy-MM-DD'),
    };
  };

  const setDates = (startDate, endDate) => {
    if (startDate) {
      localStorage.setItem('filterStartDate', JSON.stringify(moment(startDate).format('yyyy-MM-DD')));
      setFilterStartDate(startDate);
    } else {
      setFilterStartDate(filterStartDate);
    }
    if (endDate) {
      localStorage.setItem('filterStartDate', JSON.stringify(moment(endDate).format('yyyy-MM-DD')));
      setFilterEndDate(endDate);
    } else {
      setFilterEndDate(filterEndDate);
    }
  };

  const value = {
    filterStartDate,
    filterEndDate,
    setDates,
    setDefaultDates,
  };

  return <FiltersContext.Provider value={value}>{!loading && children}</FiltersContext.Provider>;
}
