/**
 * @flow
 *
 * @format
 */

import moment from 'moment';
import { useFilters } from 'src/contexts/FiltersContext';
import useSWR from 'swr';
import { fetcher } from './fetcher';
import { useListScenarios } from './scenarios';

type SecondaryMissions = {
  finished: number,
  total: number,
};

export interface Result {
  batteryAmountUsed: number;
  cityId: string;
  cluesUsed: number;
  distance: number;
  duration: number;
  gameOvers: number;
  isSuccess: boolean;
  medals: Array<string>;
  notFinished: boolean;
  scenarioId: string;
  scenarioIsSchool: boolean;
  scenarioVersion: string;
  secondaryMissions: SecondaryMissions;
  startDate: number;
  storyPercent: number;
  wrongAnswers: number;
  userId: string;
}

const useListResults = () => {
  const { data, error } = useSWR('results', fetcher, {
    revalidateIfStale: true,
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
  });
  return {
    results: data ?? [],
    resultsIsLoading: !error && !data,
    resultsIsError: error,
  };
};

const useListMissionResults = (scenarioId: string) => {
  const { data, error } = useSWR(`results/${scenarioId}`, fetcher, {
    revalidateIfStale: true,
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
  });

  let missionResults = [];
  if (data) {
    Object.values(data).forEach((result, index) => {
      const userId = Object.keys(data)[index];
      try {
        Object.values(result).forEach((r) => {
          r.userId = userId;
          missionResults.push(r);
        });
      } catch (error) {
        console.log(error);
      }
    });
  }

  return {
    scenarioResults: missionResults,
    scenarioResultsIsLoading: !error && !data,
    scenarioResultsIsError: error,
  };
};

const useListScenariosSales = (scenarioId: string) => {
  const { scenarios, scenarioIsLoading } = useListScenarios();
  let currentScenario;
  let scenarioPrice;
  let scenarioPriceSpecialOffer;
  if (!scenarioIsLoading && scenarios) {
    currentScenario = scenarios[scenarioId];
    scenarioPrice = currentScenario.vendingInfo.price / 100;
    scenarioPriceSpecialOffer = scenarioPrice * 0.5;
  }

  const { data, error } = useSWR(`scenariosSales/${scenarioId}`, fetcher, {
    revalidateIfStale: true,
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
  });
  const { filterStartDate, filterEndDate } = useFilters();
  let values;
  let daysLabels = [];
  let isFreeSales = {};
  let welcomeSales = {};
  let launchSales = {};
  let total = 0;
  let totalBasic = 0;
  let totalFree = 0;
  let totalWelcome = 0;
  let totalLaunch = 0;

  if (data) {
    values = Object.values(data).filter((item: any) => {
      return (
        moment(parseInt(item.transactionDate)).format('yyyy-MM-DD') >= filterStartDate &&
        moment(parseInt(item.transactionDate)).format('yyyy-MM-DD') <= filterEndDate
      );
    });

    Object.values(values).forEach((value) => {
      if (value.isFree) {
        totalFree = totalFree + 1;
        return;
      } else if (value.specialOffer && value.specialOffer === 'welcome') {
        totalWelcome = totalWelcome + scenarioPrice * 0.5;
        total = total + scenarioPriceSpecialOffer;
      } else if (value.specialOffer && value.specialOffer === 'launch') {
        totalLaunch = totalLaunch + scenarioPriceSpecialOffer;
        total = total + scenarioPriceSpecialOffer;
      } else {
        totalBasic = totalBasic + scenarioPrice;
        total = total + scenarioPrice;
      }
    });
  }

  return {
    scenariosSales: {
      total,
      totalBasic,
      totalFree,
      totalWelcome,
      totalLaunch,
      daysLabels,
      isFreeSales,
      welcomeSales,
      launchSales,
    },
    scenariosSalesIsLoading: !error && !data,
    scenariosSalesIsError: error,
  };
};

export { useListResults, useListMissionResults, useListScenariosSales };
