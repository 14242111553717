/**
 * @format
 *
 * @flow
 */

import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';
import { db } from 'src/services/Firebase/firebase-config';
import { useTranslation } from 'react-i18next';

type Props = {
  result: any,
};

const LastGames = ({ result }: Props) => {
  const { t } = useTranslation();
  const [user, setUser] = React.useState<any>();

  const getUser = async (userId) => {
    const result = await db.ref(`users/${userId}`).once('value');
    if (result.exists()) {
      const resultValue = result.val();
      if (resultValue) {
        setUser(resultValue);
      }
    } else {
      setUser('-');
    }
  };

  React.useEffect(() => {
    if (result && result.userId) {
      getUser(result.userId);
    } else if (result && !result.userId) {
      setUser('-');
    }
  }, [result]);

  const timestamp = parseInt(result.startDate);
  return (
    <tr>
      <th scope="row">
        <span className="mb-0 text-sm">{timestamp ? moment(timestamp).format('DD/MM/YYYY HH:mm') : '-'}</span>
      </th>
      <th scope="row">
        <Link to={`/missionDetails/${result.scenarioId}`}>{result.scenarioId}</Link>
      </th>
      <th scope="row">
        <Link to={`/userDetails/${result.userId}`}>
          {user?.userName ? (user?.userName === 'atlantideAgent' ? 'Tisseur EVA' : user?.userName) : user?.collarNumber}
        </Link>
      </th>
      <th scope="row">
        {result.isSuccess ? (
          <Badge color="success" pill>
            {t('screens.dashboard.lastTenGames.fields.yes')}
          </Badge>
        ) : (
          <Badge color="danger" pill>
            {t('screens.dashboard.lastTenGames.fields.no')}
          </Badge>
        )}
      </th>
      <th scope="row">{Math.round(result.storyPercent * 100)} %</th>
      <th scope="row">
        {t('screens.dashboard.lastTenGames.fields.clues')} : {result.cluesUsed},{' '}
        {t('screens.dashboard.lastTenGames.fields.duration')} : {Math.round(result.duration / 60000)}{' '}
        {t('screens.dashboard.lastTenGames.fields.min')}, {t('screens.dashboard.lastTenGames.fields.gameOver')} :{' '}
        {result.gameOvers}, {t('screens.dashboard.lastTenGames.fields.wrongAnswers')} : {result.wrongAnswers}
      </th>
    </tr>
  );
};

export default LastGames;
