/**
 * @flow
 *
 * @format
 */

import React, { useContext, useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import { auth } from 'src/services/Firebase/firebase-config';

type AuthContextType = {
  currentUser: any,
  errorClaims: boolean,
  setErrorClaims: (newValue: boolean) => void,
  login: (email: string, password: string) => Promise<firebase.auth.UserCredential>,
  logout: () => Promise<void>,
};

type AuthProviderProps = {
  children: any,
};

const initialContext = {
  currentUser: null,
  errorClaims: false,
  setErrorClaims: () => {},
  login: (email: string, password: string) => auth.signInWithEmailAndPassword(email, password),
  logout: () => auth.signOut(),
};

const AuthContext = React.createContext<AuthContextType>(initialContext);

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }: AuthProviderProps) {
  const [currentUser, setCurrentUser] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const [errorClaims, setErrorClaims] = useState<boolean>(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        auth.currentUser.getIdTokenResult().then((idTokenResult) => {
          if (!idTokenResult.claims.admin && !idTokenResult.claims.stats) {
            setErrorClaims(true);
            initialContext.logout();
          } else {
            setCurrentUser(user);
            setLoading(false);
          }
        });
      } else {
        setLoading(false);
      }
    });

    return unsubscribe;
  }, []);

  const value = {
    ...initialContext,
    currentUser,
    errorClaims,
    setErrorClaims,
  };

  return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>;
}
