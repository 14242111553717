/**
 * @format
 *
 * @flow
 */

import React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { Container } from 'reactstrap';
import { Footer, Header, Sidebar } from 'src/components/Layout';
import { ProtectedRoute } from 'src/components/ProtectedRoute';
import { routes } from 'src/constants/routes';
import { DashboardScreen } from 'src/pages/Dashboard';
import { MissionsListScreen, MissionDetailsScreen } from 'src/pages/Missions';
import UserDetailsScreen from 'src/pages/Users';

const AdminLayout = () => {
  const mainContent = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  return (
    <>
      <Sidebar />
      <div className="main-content" ref={mainContent}>
        <Header />
        <Switch>
          <Route exact path="/">
            <Redirect to={routes.dashboard} />
          </Route>
          <ProtectedRoute exact path={routes.dashboard} component={DashboardScreen} />
          <ProtectedRoute exact path={routes.missionsList} component={MissionsListScreen} />
          <ProtectedRoute exact path={routes.missionDetails} component={MissionDetailsScreen} />
          <ProtectedRoute exact path={routes.userDetails} component={UserDetailsScreen} />
        </Switch>
        <Container fluid>
          <Footer />
        </Container>
      </div>
    </>
  );
};

export default AdminLayout;
