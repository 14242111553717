/** @format */

import React from 'react';
import classnames from 'classnames';
import { Pagination as PaginationDiv, PaginationItem, PaginationLink } from 'reactstrap';

import { usePaginationHook, DOTS } from 'src/hooks/UsePaginationHook';

type Props = {
  onPageChange: any,
  totalCount: number,
  siblingCount: number | null,
  currentPage: number,
  pageSize: number,
};

const Pagination = ({ onPageChange, totalCount, siblingCount = 1, currentPage, pageSize }: Props) => {
  const paginationRange = usePaginationHook({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <PaginationDiv className="pagination justify-content-end mb-0" listClassName="justify-content-end mb-0">
      <PaginationItem
        className={classnames('pagination-item', {
          disabled: currentPage === 1,
        })}
      >
        <PaginationLink onClick={onPrevious} tabIndex="-1">
          <i className="fas fa-angle-left" />
          <span className="sr-only">Previous</span>
        </PaginationLink>
      </PaginationItem>
      {paginationRange.map((pageNumber, p) => {
        if (pageNumber === DOTS) {
          return (
            <li key={p} className="pagination-item dots">
              &#8230;
            </li>
          );
        }

        return (
          <PaginationItem className={classnames('pagination-item', { active: pageNumber === currentPage })}>
            <PaginationLink onClick={() => onPageChange(pageNumber)}>{pageNumber} </PaginationLink>
          </PaginationItem>
        );
      })}
      <PaginationItem
        className={classnames('pagination-item', {
          disabled: currentPage === lastPage,
        })}
      >
        <PaginationLink onClick={onNext}>
          <i className="fas fa-angle-right" />
          <span className="sr-only">Next</span>
        </PaginationLink>
      </PaginationItem>
    </PaginationDiv>
  );
};

export default Pagination;
