/** @format */
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { availableLanguages } from './languages';
import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/fr';

import en from './en/default.json';
import fr from './fr/default.json';
import { Images } from '..';

// The translation files
const resources = {
  fr: {
    translation: fr,
  },
  en: {
    translation: en,
  },
};

// Used to init i18n in the app
export const initI18n = async () => {
  await i18next.use(initReactI18next).init({
    fallbackLng: availableLanguages,
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: true,
    },
    resources,
    // Used to remove keys that are useless and never used
    parseMissingKeyHandler: () => '',
  });
  moment.updateLocale(i18next.language, { week: { dow: 1 } });
  console.log('[INFO] Internationalization ready');
};

// Function used to change the language of the app
export const changeLocale = (lng) => {
  i18next.changeLanguage(lng);
  moment.updateLocale(lng, { week: { dow: 1 } });
  console.log(`[INFO] Locale changed to ${lng}`);
};

export const languageToFlag = () => {
  const language = i18next.language.split('-')[0];
  switch (language) {
    case 'en':
      return Images.flags.en;
    case 'fr':
    default:
      return Images.flags.fr;
  }
};

export const switchLanguage = () => {
  const index = availableLanguages.indexOf(i18next.language);
  const newLng = availableLanguages[(index + 1) % availableLanguages.length];
  changeLocale(newLng);
  return newLng;
};
