/** @format */

const Images = {
  flags: {
    en: require('src/assets/img/flags/en_uk.png'),
    fr: require('src/assets/img/flags/fr_fr.png'),
  },
};

export { Images };
