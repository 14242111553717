/**
 * @flow
 *
 * @format
 */

import React from 'react';
import {
  Container,
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Spinner,
} from 'reactstrap';
import { Footer } from 'src/components/Layout';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'src/contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import { functions } from '../../services/Firebase/firebase-config';

const LoginScreen = () => {
  const { t } = useTranslation();
  const [userEmail, setUserEmail] = React.useState<string>('');
  const [userPassword, setUserPassword] = React.useState<string>('');
  const [formPending, setFormPending] = React.useState<boolean>(false);
  const [passwordReset, setPasswordReset] = React.useState<string>('');
  const [error, setError] = React.useState<string | null>(null);

  const { login, errorClaims, setErrorClaims } = useAuth();
  const history = useHistory();

  React.useEffect(() => {
    document.body.classList.add('bg-default');
    return () => {
      document.body.classList.remove('bg-default');
    };
  }, []);

  const onLogin = async () => {
    try {
      setError(null);
      setErrorClaims(false);
      setFormPending(true);
      await login(userEmail, userPassword);
      history.push('/');
    } catch {
      setError(t('screens.login.logInError'));
    }
    setFormPending(false);
  };

  const askResetPassword = async () => {
    setFormPending(true);
    setError(null);
    setErrorClaims(false);
    try {
      setPasswordReset('');
      await functions.httpsCallable('askResetPassword')({
        email: userEmail,
        forStats: true,
      });
      setPasswordReset(userEmail);
    } catch (err) {
      console.log(err);
      setError(t('screens.login.resetError'));
    }
    setFormPending(false);
  };

  return (
    <>
      <div className="main-content">
        <div className="header eva-gradient py-7 py-lg-8">
          <Container>
            <div className="header-body text-center mb-7">
              <Row className="justify-content-center">
                <Col lg="5" md="6">
                  <h1 className="text-white">{t('general.welcome')}</h1>
                </Col>
              </Row>
            </div>
          </Container>
          <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon className="fill-default" points="2560 0 2560 100 0 100" />
            </svg>
          </div>
        </div>
        {/* Page content */}
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <Card className="bg-secondary shadow border-0">
                <CardBody className="px-lg-5 py-lg-5">
                  <div className="text-center text-muted mb-4">
                    <small>{t('screens.login.logInWithCredentials')}</small>
                  </div>
                  <Form role="form">
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder={t('screens.login.form.email')}
                          type="email"
                          autoComplete="new-email"
                          onChange={(e) => setUserEmail(e.target.value)}
                          required
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder={t('screens.login.form.password')}
                          type="password"
                          autoComplete="new-password"
                          onChange={(e) => setUserPassword(e.target.value)}
                          required
                        />
                      </InputGroup>
                    </FormGroup>
                    <div style={{ color: 'red' }}>
                      {(error || errorClaims) && <small>{errorClaims ? t('screens.login.claimsError') : error}</small>}
                    </div>
                    <div style={{ color: 'gray' }}>
                      {passwordReset && <small>{`${t('screens.login.passwordReset')} ${passwordReset}`}</small>}
                    </div>

                    <div className="text-center">
                      <Button
                        className="mt-4 mb-2 "
                        type="button"
                        onClick={() => askResetPassword()}
                        disabled={formPending}
                      >
                        {formPending ? (
                          <Spinner size="sm">{t('general.loading')}</Spinner>
                        ) : (
                          t('screens.login.resetPassword')
                        )}
                      </Button>
                      <Button
                        className="mt-2 mb-2 gradient-button"
                        type="button"
                        onClick={() => onLogin()}
                        disabled={formPending}
                      >
                        {formPending ? <Spinner size="sm">{t('general.loading')}</Spinner> : t('screens.login.logIn')}
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default LoginScreen;
