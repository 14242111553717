/**
 * @flow
 *
 * @format
 */

import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';
import { type Result } from 'src/services/data/results';
import { db } from 'src/services/Firebase/firebase-config';

export interface Props {
  result: Result;
}

const MissionResultsTableLines = ({ result }: Props) => {
  const { t } = useTranslation();
  const [user, setUser] = React.useState<any>();

  const getUser = async (userId) => {
    const result = await db.ref(`users/${userId}`).once('value');
    if (result.exists()) {
      const resultValue = result.val();
      if (resultValue) {
        setUser(resultValue);
      }
    } else {
      setUser('-');
    }
  };

  React.useEffect(() => {
    if (result && result.userId) {
      getUser(result.userId);
    } else if (result && !result.userId) {
      setUser('-');
    }
  }, [result]);

  const timestamp = parseInt(result.startDate);
  return (
    <tr>
      <th scope="row">
        <span className="mb-0 text-sm">{timestamp ? moment(timestamp).format('DD/MM/YYYY HH:mm') : '-'}</span>
      </th>
      <th scope="row">
        <Link to={`/userDetails/${result.userId}`}>
          {user?.userName ? (user.userName === 'atlantideAgent' ? 'Tisseur EVA' : user.userName) : user?.collarNumber}
        </Link>
      </th>
      <th scope="row">
        {!result.notFinished ? (
          <Badge color="success" pill>
            {t('screens.missionDetails.lastResults.fields.yes')}
          </Badge>
        ) : (
          <Badge color="danger" pill>
            {t('screens.missionDetails.lastResults.fields.no')}
          </Badge>
        )}
      </th>
      <th scope="row">
        {result.isSuccess ? (
          <Badge color="success" pill>
            {t('screens.missionDetails.lastResults.fields.yes')}
          </Badge>
        ) : (
          <Badge color="danger" pill>
            {t('screens.missionDetails.lastResults.fields.no')}
          </Badge>
        )}
      </th>
      <th scope="row">{Math.round(result.storyPercent * 100)} %</th>
      <th scope="row">
        {t('screens.missionDetails.lastResults.fields.clues')} : {result.cluesUsed},{' '}
        {t('screens.missionDetails.lastResults.fields.duration')} : {Math.round(result.duration / 60000)}{' '}
        {t('screens.missionDetails.lastResults.fields.min')}, {t('screens.missionDetails.lastResults.fields.gameOver')}{' '}
        : {result.gameOvers},{t('screens.missionDetails.lastResults.fields.wrongAnswers')} : {result.wrongAnswers}
      </th>
    </tr>
  );
};

export default MissionResultsTableLines;
