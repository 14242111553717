/**
 * @flow
 *
 * @format
 */

import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { routes } from 'src/constants/routes';
import { useAuth } from 'src/contexts/AuthContext';

type Props = {
  component: any,
};

const ProtectedRoute: React.FC<Props> = ({ component: Component, ...rest }) => {
  const { currentUser } = useAuth();
  return (
    <Route
      {...rest}
      render={(props) => {
        return currentUser ? <Component {...props} /> : <Redirect to={routes.login} />;
      }}
    ></Route>
  );
};

export default ProtectedRoute;
