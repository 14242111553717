/** @format */
import moment from 'moment';

const downloadCsvContent = (csvContent, fileName) => {
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', fileName);
  document.body.appendChild(link);

  link.click();
};

export const exportAndDownloadCSV = (t, resultsArray, fileName, scenarios) => {
  console.log('exporting csv');

  let csvContent = 'data:text/csv;charset=utf-8,';
  const rows = [
    'Date',
    'Énigme',
    'Utilisateur',
    'Succès',
    'Progression',
    t('screens.dashboard.lastTenGames.fields.clues'),
    t('screens.dashboard.lastTenGames.fields.duration'),
    t('screens.dashboard.lastTenGames.fields.gameOver'),
    t('screens.dashboard.lastTenGames.fields.wrongAnswers'),
    'Score',
  ];
  csvContent += rows.join(',') + '\r\n';

  resultsArray
    .sort((a, b) => b.startDate - a.startDate)
    .forEach((rowObject) => {
      let rowArray = [];
      const timestamp = parseInt(rowObject.startDate || rowObject.timestamp);
      const startDate = timestamp ? moment(timestamp).format('DD/MM/YYYY HH:mm') : '-';
      rowArray.push(startDate);
      const scenarioName = scenarios.find((sc) => sc.value === rowObject.scenarioId)?.label;
      rowArray.push(`${rowObject.scenarioId} (${(scenarioName || '???').replaceAll(',', ' ')})`);
      rowArray.push(
        `${rowObject.userId}${rowObject.isSuccess ? ` (${(rowObject.userName || '???').replaceAll(',', ' ')})` : ''}`,
      );
      rowArray.push(rowObject.isSuccess);
      rowArray.push(`${Math.round(rowObject.storyPercent * 100)} %`);
      rowArray.push(rowObject.cluesUsed);
      rowArray.push(`${Math.round(rowObject.duration / 60000)} ${t('screens.dashboard.lastTenGames.fields.min')}`);
      rowArray.push(rowObject.gameOvers);
      rowArray.push(rowObject.wrongAnswers);
      rowArray.push(rowObject.score || 0);

      let row = rowArray.join(',');
      csvContent += row + '\r\n';
    });

  downloadCsvContent(csvContent, fileName);
};

export const exportAndDownloadMultipleMissionsCSV = (
  // Array of results
  resultsArray,
  fileName,
  // Array of { value, label }
  selectedScenarios,
) => {
  console.log('exporting csv');

  let csvContent = 'data:text/csv;charset=utf-8,';
  const rows = ['Utilisateur', 'Énigmes', 'Score cumulé'];
  csvContent += rows.join(',') + '\r\n';

  // userId - { date: number, userId: string, userName?: string, scenarioIds: string[], cumulatedScore: int }
  const resultsByUser = {};
  // Remove failed results and the ones from the unwanted scenarios
  const resultsSelectedScenarios = resultsArray.filter(
    (result) =>
      result.isSuccess && selectedScenarios.map((sc) => sc.value).includes(result.scenarioId) && result.score > 0,
  );

  resultsSelectedScenarios.forEach((rowObject) => {
    const timestamp = parseInt(rowObject.startDate || rowObject.timestamp);
    const scenarioName = selectedScenarios.find((sc) => sc.value === rowObject.scenarioId)?.label;
    if (resultsByUser[rowObject.userId]) {
      resultsByUser[rowObject.userId].scenarioIds.push(`${rowObject.scenarioId} (${scenarioName || '???'})`);
      resultsByUser[rowObject.userId].cumulatedScore += rowObject.score;
      if (
        (!resultsByUser[rowObject.userId].userName || resultsByUser[rowObject.userId].timestamp < timestamp) &&
        rowObject.userName
      ) {
        // Updated date and userName
        resultsByUser[rowObject.userId].date += timestamp;
        resultsByUser[rowObject.userId].userName += rowObject.userName;
      }
    } else {
      resultsByUser[rowObject.userId] = {
        date: timestamp,
        userId: rowObject.userId,
        userName: rowObject.userName,
        scenarioIds: [`${rowObject.scenarioId} (${scenarioName || '???'})`],
        cumulatedScore: rowObject.score,
      };
    }
  });

  Object.values(resultsByUser)
    .sort((a, b) => b.cumulatedScore - a.cumulatedScore)
    .forEach((rowObject) => {
      let rowArray = [];
      rowArray.push(`${rowObject.userId} (${(rowObject.userName || '???').replaceAll(',', ' ')})`);
      rowArray.push(rowObject.scenarioIds.join(' | ').replaceAll(',', ' '));
      rowArray.push(rowObject.cumulatedScore);

      let row = rowArray.join(',');
      csvContent += row + '\r\n';
    });

  downloadCsvContent(csvContent, fileName);
};
