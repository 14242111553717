/**
 * @flow
 *
 * @format
 */

import useSWR from 'swr';
import { fetcher } from './fetcher';

type ScoreDetails = {
  cluesUsed: number,
  duration: number,
  gameOvers: number,
  wrongAnswers: number,
};

export interface Score {
  timestamp: number;
  userId: string;
  userName: string;
  score: number;
  isSuccess: boolean;
  scoreDetails: ScoreDetails;
}

export type Review = {
  timestamp: number,
  userId: string,
  userName: string,
  note: number,
  comment: string,
};

export interface ScenarioScoresAndReviews {
  reviews: Array<Review>;
  scores: Array<Score>;
}

const useScoresAndReviews = () => {
  const { data, error } = useSWR('scoresAndReviews', fetcher, {
    revalidateIfStale: true,
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
  });
  return {
    scoresAndReviews: data ?? [],
    scoresAndReviewsIsLoading: !error && !data,
    scoresAndReviewsIsError: error,
  };
};

const useGetScenarioScoresAndReviews = (scenarioId: string) => {
  const { data, error } = useSWR(`scoresAndReviews/${scenarioId}`, fetcher, {
    revalidateIfStale: true,
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
  });

  return {
    scenarioScoresAndReviews: data,
    scenarioScoresAndReviewsIsLoading: !error && !data,
    scenarioScoresAndReviewsError: error,
  };
};

export { useScoresAndReviews, useGetScenarioScoresAndReviews };
